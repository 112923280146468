import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {collection, endAt, getDocs, orderBy, query, startAt} from 'firebase/firestore';
import {firestore} from '../firebase';
import VisitsChart from './VisitsChart';
import GeneralInfo from './GeneralInfo';
import {format} from 'date-fns';
import {VisitLog} from './VisitLogs.type';
import ReferrerBreakdown from "./ReferrerBreakdown.tsx"; // Import the VisitLog type

// Helper function to get the default date range
const getDefaultDateRange = () => {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(last7Days.getDate() - 7);
    return {start: last7Days, end: today};
};

const ReportDetails = () => {
    const {shortCode} = useParams<{ shortCode: string }>(); // Get shortCode from route params
    const [visitLogs, setVisitLogs] = useState<VisitLog[]>([]); // Store the visit logs
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<{ start: Date; end: Date }>(getDefaultDateRange());

    // Breakdown storage
    const [osBreakdown, setOsBreakdown] = useState<{ [key: string]: number }>({});
    const [browserBreakdown, setBrowserBreakdown] = useState<{ [key: string]: number }>({});
    const [cityBreakdown, setCityBreakdown] = useState<{ [key: string]: number }>({});
    const [countryBreakdown, setCountryBreakdown] = useState<{ [key: string]: number }>({});
    const [referrerBreakdown, setReferrerBreakdown] = useState<{ [key: string]: number }>({});


    // Fetch visit logs from the nested /visitLogs collection
    useEffect(() => {
        const fetchVisitLogs = async () => {
            setLoading(true);
            try {
                const visitLogsRef = collection(firestore, `shortenedURLs/${shortCode}/visitLogs`);
                const startTimestamp = dateRange.start;
                const endTimestamp = dateRange.end;

                const q = query(
                    visitLogsRef,
                    orderBy('timestamp'),
                    startAt(startTimestamp),
                    endAt(endTimestamp)
                );

                const querySnapshot = await getDocs(q);
                const logs: VisitLog[] = querySnapshot.docs.map((doc) => doc.data() as VisitLog);
                setVisitLogs(logs);

                // Calculate top 20 countries and breakdowns
                const countryCounts: { [key: string]: number } = {};
                const osCounts: { [key: string]: number } = {};
                const browserCounts: { [key: string]: number } = {};
                const cityCounts: { [key: string]: number } = {};
                const referrerCounts: { [key: string]: number } = {};


                logs.forEach((log) => {
                    const country = log.location?.country || 'Unknown';
                    const os = log.os || 'Unknown';
                    const browser = log.browser || 'Unknown';
                    const city = log.location?.city || 'Unknown';
                    const referrer = log.referer || 'Unknown';


                    if (!countryCounts[country]) countryCounts[country] = 0;
                    countryCounts[country]++;

                    if (!osCounts[os]) osCounts[os] = 0;
                    osCounts[os]++;

                    if (!browserCounts[browser]) browserCounts[browser] = 0;
                    browserCounts[browser]++;

                    if (!cityCounts[city]) cityCounts[city] = 0;
                    cityCounts[city]++;


                    if (!referrerCounts[referrer]) referrerCounts[referrer] = 0;
                    referrerCounts[referrer]++;

                });


                setOsBreakdown(osCounts);
                setBrowserBreakdown(browserCounts);
                setCityBreakdown(cityCounts);
                setCountryBreakdown(countryCounts);
                setReferrerBreakdown(referrerCounts);


            } catch (err) {
                setError('Failed to fetch visit logs.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchVisitLogs();
    }, [shortCode, dateRange]);

    const handleDateRangeChange = (start: Date, end: Date) => {
        setDateRange({start, end});
    };

    const visitDates = visitLogs.map(log => new Date(log.timestamp.seconds * 1000));
    const dailyVisits = visitDates.reduce((acc: { [key: string]: number }, date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        if (!acc[formattedDate]) acc[formattedDate] = 0;
        acc[formattedDate]++;
        return acc;
    }, {});

    return (
        <div className="container">
            <div className="header">
                <button onClick={() => window.history.back()} >
                    Back to Reports
                </button>
                <h1>Report Details for {shortCode}</h1>
            </div>


            <GeneralInfo shortCode={shortCode!}/>

            <div style={{marginTop: '20px', maxWidth: '300px'}}>
                <div>
                    <h3>Filter by Date Range</h3>
                    <input
                        type="date"
                        value={format(dateRange.start, 'yyyy-MM-dd')}
                        onChange={(e) => handleDateRangeChange(new Date(e.target.value), dateRange.end)}
                    />
                    <input
                        type="date"
                        value={format(dateRange.end, 'yyyy-MM-dd')}
                        onChange={(e) => handleDateRangeChange(dateRange.start, new Date(e.target.value))}
                    />
                </div>
            </div>
            {loading && <p>Loading visit logs...</p>}
            {error && <p style={{color: 'red'}}>{error}</p>}

            {!loading && visitLogs.length > 0 && (
                <>
                <VisitsChart
                    dailyVisits={dailyVisits}
                    osBreakdown={osBreakdown}
                    browserBreakdown={browserBreakdown}
                    cityBreakdown={cityBreakdown}
                    countryBreakdown={countryBreakdown}
                />
                <ReferrerBreakdown referrerBreakdown={referrerBreakdown} />
                </>

            )}


        </div>
    );
};



export default ReportDetails;
