// src/components/ReferrerBreakdown.tsx
import React from 'react';

interface ReferrerBreakdownProps {
    referrerBreakdown: { [key: string]: number };
}

const ReferrerBreakdown: React.FC<ReferrerBreakdownProps> = ({ referrerBreakdown }) => {
    return (
        <div className="chart-grid">
            <h3>Referrer Breakdown</h3>
            <table>
                <thead>
                <tr>
                    <th>Referrer</th>
                    <th>Count</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(referrerBreakdown).map(([referrer, count]) => (
                    <tr key={referrer}>
                        <td>{referrer}</td>
                        <td>{count}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReferrerBreakdown;
